import { defineStore } from "pinia";

import type IDialog from "@/interfaces/IDialog";

export interface State {
  dialogs: IDialog[];
  counselorName: string | null;
  isCounselorStateLabel: boolean;
}

export const useConversationStore = defineStore("conversation", {
  state: (): State => ({
    dialogs: [],

    counselorName: null,
    isCounselorStateLabel: true,
  }),

  actions: {
    setDialogs(dialogs: IDialog[]) {
      this.dialogs = dialogs;
    },

    resetState() {
      this.dialogs = [];
      this.counselorName = null;
    },

    setCounselorName(name: string) {
      this.counselorName = name;
    },

    setIsCounselorStateLabel(isStateLabel: boolean) {
      this.isCounselorStateLabel = isStateLabel;
    },
  },
});
