import axios, { type InternalAxiosRequestConfig, type AxiosInstance } from "axios";
import router from "../router";

import { getRefreshToken } from "./auth";

import { useAuthStore } from "@/store/Auth";
import { useTenantStore } from "@/store/Tenant";

import i18n from "@/i18n";

let safezoneApi: AxiosInstance;

const loginInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const authStore = useAuthStore();

  if (authStore.isLoggedIn) {
    const token = authStore.token;

    // TODO
    // @ts-ignore
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  // TODO
  // @ts-ignore
  config.headers["Accept-Language"] = i18n.global.locale.value;

  return config;
};

const responseFailedInterceptor = (error: any) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return Promise.reject(error);
  }

  const authStore = useAuthStore();

  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url.includes("token/refresh")) {
    // will error
    authStore.removeAuth;
    router.push({ name: "root" });
    return Promise.reject(error);
  }

  if (!authStore.refreshToken) {
    return Promise.reject(error);
  }

  // Try request again with new token
  return getRefreshToken(authStore.refreshToken)
    .then((token) => {
      // set token in store
      authStore.setToken(token.access);
      authStore.setRefreshToken(token.refresh);

      // New request with new token
      const config = error.config;
      config.headers["Authorization"] = `Bearer ${token.access}`;
      return axios.request(config);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const tenantInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const tenantStore = useTenantStore();

  const frontendTag = tenantStore.tenant?.frontendTag;

  if (config.method == "post" || config.method === "put") {
    if (!config.data) {
      config.data = {
        tenant: frontendTag,
      };
    } else {
      config.data["tenant"] = frontendTag;
    }
  } else {
    if (!config.params) {
      config.params = {
        tenant: frontendTag,
      };
    } else {
      config.params["tenant"] = frontendTag;
    }
  }

  return config;
};

export function initializeApi(config: any) {
  safezoneApi = axios.create({
    baseURL: config.VITE_API_URL,
  });

  safezoneApi.interceptors.request.use(loginInterceptor);
  safezoneApi.interceptors.request.use(tenantInterceptor);
  safezoneApi.interceptors.response.use(undefined, responseFailedInterceptor);
}

export { safezoneApi };
