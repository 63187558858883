import { safezoneApi } from "./api";
import router from "../router";

/**
 * Gets all tenant information
 *
 * @return The tenant tag
 */
const getTenant = async (tenantTag: string) => {
  try {
    const response = await safezoneApi.get(`tenant/tenant/${tenantTag}/`);
    return response.data;
  } catch (error: any) {
    // In case the tenant does not exist or is inactive, redirect to the root page to fall back to
    // the default tenant.
    if (error.response.status === 404) {
      await router.push({ name: "root" });
    }
    throw error;
  }
};

export { getTenant };
