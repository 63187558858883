<template>
  <VueMultiselect
    v-bind="$attrs"
    :placeholder="t('general.pleaseChoose')"
    :data-testid="props.testId"
  />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import VueMultiselect from "vue-multiselect";

const { t } = useI18n();

const props = withDefaults(defineProps<{ testId?: string }>(), {
  testId: "multiselect",
});
</script>
