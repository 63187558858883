import { defineStore } from "pinia";

import type ITenant from "@core/src/types/ITenant";
import { getLanguages } from "@/services/language";
import type ILanguage from "@/interfaces/ILanguage";

interface State {
  tenant: ITenant | null;
}

export const useTenantStore = defineStore("tenant", {
  state: (): State => ({
    tenant: null,
  }),

  actions: {
    setTenant(tenant: ITenant) {
      this.tenant = tenant;
    },
    async updateLanguages() {
      // Re-fetch all languages and update the available ones in the store, e.g. because the selected language changed.
      const availableLanguageIds = this.tenant?.availableLanguages.map(
        (lang: ILanguage) => lang.id
      );
      const languages = await getLanguages();
      const availableLanguages = languages.filter((l: ILanguage) => {
        return availableLanguageIds?.includes(l.id);
      });
      if (this.tenant) {
        this.tenant.availableLanguages = availableLanguages;
      }
    },
  },
  getters: {
    tenantName(state) {
      return state.tenant?.name;
    },

    privacyPolicy(state) {
      return state.tenant?.privacyPolicy;
    },

    legalInformation(state) {
      return state.tenant?.legalInformation;
    },

    // get is not allowing parameters, so we work with a small hack and the
    // getter returns a function with a parameter
    getLanguageById(state) {
      return (id: number) => {
        return (
          state.tenant?.availableLanguages.find(
            (language: ILanguage) => language.id === id
          ) || null
        );
      };
    },
  },
});
