import i18n, { setLocale } from "@/i18n";

import { useAuthStore } from "@/store/Auth";
import { useTenantStore } from "@/store/Tenant";
import { useConversationStore } from "@/store/Conversation";
import { destroyRefreshToken } from "@/services/auth";

import type { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

const Home = () => import("@/views/SafezoneHome.vue");
const Registration = () => import("@/views/SafezoneRegistration.vue");
const Login = () => import("@/views/SafezoneLogin.vue");
const Widget = () => import("@/views/SafezoneWidget.vue");
const ForgotPassword = () => import("@/views/ForgotPassword.vue");
const PasswordReset = () => import("@/views/PasswordReset.vue");
const Profile = () => import("@/views/SafezoneProfile.vue");
const BlendedCounseling = () => import("@/views/BlendedCounseling.vue");

export const availableLanguages = ["de", "fr", "it", "rm"];

const languageBeforeEnter = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: Function
) => {
  const toLanguage = Array.isArray(to.params.language)
    ? to.params.language[0]
    : to.params.language;

  // Check if given language is an available language
  if (availableLanguages.includes(toLanguage)) {
    setLocale(toLanguage);
    return next();
  }

  let defaultLanguage = i18n.global.locale.value;

  // Check if a language is preferred by looping through browser languages
  for (let language of navigator.languages) {
    // We need to split to handle e.g. fr-CH
    language = language.split("-")[0];

    if (availableLanguages.includes(language)) {
      defaultLanguage = language;
      break;
    }
  }

  // Redirect to default language
  return next({ path: defaultLanguage + to.path, query: to.query });
};

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "root",
    components: {},
    beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: Function
    ) {
      // Check if a language is preferred by looping through browser languages
      for (let language of navigator.languages) {
        // We need to split to handle e.g. fr-CH
        language = language.split("-")[0];
        if (availableLanguages.includes(language)) {
          return next({
            path: language + to.path,
            query: to.query,
          });
        }
      }
      // Redirect to default language
      next({ path: i18n.global.locale + to.path, query: to.query });
    },
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language",
    name: "home",
    component: Home,
    beforeEnter: languageBeforeEnter,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language/register",
    name: "registration",
    component: Registration,
    beforeEnter: languageBeforeEnter,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language?/blended-counseling/:counselorId",
    name: "blended-counseling",
    component: BlendedCounseling,
    beforeEnter: languageBeforeEnter,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language/login",
    name: "login",
    component: Login,
    beforeEnter: languageBeforeEnter,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    beforeEnter: languageBeforeEnter,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language/password-reset",
    name: "password-reset",
    component: PasswordReset,
    beforeEnter: languageBeforeEnter,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language/profile",
    name: "profile",
    component: Profile,
    beforeEnter: languageBeforeEnter,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/:language/logout",
    name: "logout",
    component: {},
    beforeEnter: async (to: any, from: any, next: any) => {
      const tenantStore = useTenantStore();
      const authStore = useAuthStore();
      const conversationStore = useConversationStore();

      // we need to redirect first. When the auth stuff is unset, then the
      // conversation is being displayed in the unauthorized mode for a brief
      // moment
      await next({
        name: "login",
        query: { tenant: tenantStore.tenant?.frontendTag },
      });

      const refreshToken = authStore.refreshToken;
      if (refreshToken) {
        await destroyRefreshToken(refreshToken);
      }
      authStore.removeAuth();
      conversationStore.resetState();
    },
  },
];

if (import.meta.env.MODE !== "production") {
  // push the route to the widget for testing purposes
  // we don't want that in prod
  routes.push({
    path: "/widget",
    name: "widget",
    component: Widget,
    meta: {
      requiresNoAuth: true,
    },
  });
}

export default routes;
