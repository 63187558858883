import { defineStore } from "pinia";

export interface State {
  token: string | null;
  refreshToken: string | null;
  id: number | null;
  dossierId: number | null;
  isBlendedCounseling: boolean;
}

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    token: null,
    refreshToken: null,
    id: null,
    dossierId: null,
    isBlendedCounseling: false,
  }),

  getters: {
    /**
     * Whether the user is logged in
     * @param {object} state Module state
     */
    isLoggedIn(state): boolean {
      return state.token !== null && state.token !== undefined;
    },
  },
  actions: {
    /**
     * Set the JWT token
     *
     * @param {object} state Vuex state
     * @param {string} token token
     */
    setToken(token: string) {
      this.token = token;
    },
    /**
     * Set the Refresh token
     *
     * @param {object} state Vuex state
     * @param {string} token token
     */
    setRefreshToken(token: string) {
      sessionStorage.setItem("refreshToken", token);
      this.refreshToken = token;
    },

    /**
     * Set the user id
     * @param id User id
     */
    setId(id: number | null) {
      this.id = id;
    },

    setDossierId(id: number) {
      this.dossierId = id;
    },

    setIsBlendedCounceling(value: boolean) {
      this.isBlendedCounseling = value;
    },

    /**
     * Remove the login information and effectively log the user out
     * @param {object} state Module state
     */
    removeAuth() {
      this.token = null;
      this.refreshToken = null;
      this.id = null;
      sessionStorage.clear();
    },

    resetState() {
      this.removeAuth();
    },

    async restoreSession() {
      const refreshTokenString = sessionStorage.getItem("refreshToken");
      if (refreshTokenString) {
        this.setRefreshToken(refreshTokenString);
      }
    },
  },
});
