<template>
  <div
    id="app"
    class="text-sm lg:text-base"
    :class="{ 'blur-sm': !isTenantReady }"
  >
    <RouterView />
    <Notification />
  </div>
</template>

<script lang="ts" setup>
import Notification from "@core/src/components/Notification.vue";
import { useTenantStore } from "./store/Tenant";
import { computed, watch, ref, type ComputedRef } from "vue";

import type ITenant from "@core/src/types/ITenant";
import { useFavicon } from "@core/src/useFavicon";

const tenantStore = useTenantStore();

// @ts-ignore some weird errors in the cli type checker
const tenant: ComputedRef<ITenant> = computed(() => tenantStore.tenant);

// @ts-ignore some weird errors in the cli type checker
useFavicon(tenant);

const isTenantReady = ref(false);

/**
 * Show the page blurry until the tenant messages are ready
 * this is to avoid the misleading display of the language keys
 */
watch(tenant, (tenant: ITenant) => {
  if (tenant) {
    isTenantReady.value = true;
  }
});
</script>

<style lang="postcss">
#app,
body {
  min-height: 100vh;
}

#app {
  @apply relative;
}

:root {
  --color-primary: theme("colors.blue.primary");
  --color-secondary: theme("colors.chat.primary");
  --color-disabled: theme("colors.blue.primary-disabled");
  --color-chatsecondary: theme("colors.chat.popup");
}
</style>
